// import { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import { useGeneratorQuery } from "wecall-config-lib";
// import card_in_hamd from "../assets/bg4.png";
// import GetRingbaNumber from "../component/GetRingbaNumber";
// import {
//   ROUTES,
//   healthInsurance,
//   sessionStorageRoutes,
// } from "../component/Constant";

// function Income({ headline }) {
//   const generatorQuery = useGeneratorQuery();

//   const setValues = (value) => {
//     sessionStorage.setItem(healthInsurance, value);
//     window._rgba_tags = window._rgba_tags || [];
//     window._rgba_tags.push({ [healthInsurance]: value });
//   };

//   const navigate = useNavigate();

//   useEffect(() => {
//     generatorQuery.set(window.location.search);
//     if (!sessionStorage.getItem(sessionStorageRoutes.lander))
//       return navigate({ pathname: "../", search: generatorQuery.get() });
//     sessionStorage.setItem(sessionStorageRoutes.income, true);
//   }, []);
//   return (
//     <>
//       <GetRingbaNumber />
//       <div className="question-wrapper">
//         <div className="headline-wrapper">
//           <h1>{headline}</h1>
//         </div>
//         <form method="POST" className="question-item">
//           <div className="question">
//             <h2>Do you have health insurance?</h2>

//             <div className="option-renderer-wrapper">
//               <div className="options-container">
//                 <input
//                   type="radio"
//                   id="health-yes"
//                   name={healthInsurance}
//                   value="yes"
//                   onChange={() => {
//                     setValues("yes");
//                     navigate("../" + ROUTES.medicareMedicaid, {
//                       search: generatorQuery.get(),
//                     });
//                   }}
//                 ></input>
//                 <label className="option-button" htmlFor="health-yes">
//                   Yes
//                 </label>
//               </div>

//               <div className="options-container">
//                 <input
//                   type="radio"
//                   id="health-no"
//                   name={healthInsurance}
//                   value="no"
//                   onChange={() => {
//                     setValues("no");
//                     navigate("../" + ROUTES.medicareMedicaid, {
//                       search: generatorQuery.get(),
//                     });
//                   }}
//                 ></input>

//                 <label className="option-button" htmlFor="health-no">
//                   No
//                 </label>
//               </div>
//             </div>
//           </div>
//         </form>
//       </div>
//       <div className="card-container">
//         <div className="mainbg">
//           <img src={card_in_hamd} alt=""></img>
//         </div>
//       </div>
//     </>
//   );
// }

// export default Income;
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGeneratorQuery } from "wecall-config-lib";
import card_in_hamd from "../assets/bg4.png";
import GetRingbaNumber from "../component/GetRingbaNumber";
import {
  ROUTES,
  healthInsurance,
  sessionStorageRoutes,
} from "../component/Constant";
function Income({ headline }) {
  const generatorQuery = useGeneratorQuery();
  const [insuranceValue, setInsuranceValue] = useState("");
  const navigate = useNavigate();

  const handleOptionChange = (value) => {
    setInsuranceValue(value);
    sessionStorage.setItem(healthInsurance, value);
    window._rgba_tags = window._rgba_tags || [];
    window._rgba_tags.push({ [healthInsurance]: value });
    navigate("../" + ROUTES.medicareMedicaid, {
      search: generatorQuery.get(),
    });
  };
  useEffect(() => {
    generatorQuery.set(window.location.search);
    if (!sessionStorage.getItem(sessionStorageRoutes.lander))
      return navigate({ pathname: "../", search: generatorQuery.get() });
    sessionStorage.setItem(sessionStorageRoutes.income, true);
  }, []);
  return (
    <>
      <GetRingbaNumber />
      <div className="question-wrapper">
        <div className="headline-wrapper">
          <h1>{headline}</h1>
        </div>
        <form method="POST" className="question-item">
          <div className="question">
            <h2>Do you have health insurance?</h2>
            <div className="option-renderer-wrapper">
              <div className="options-container">
                <input
                  type="radio"
                  id="health-yes"
                  name={healthInsurance}
                  value="yes"
                  checked={insuranceValue === "yes"}
                  onChange={() => handleOptionChange("yes")}
                ></input>
                <label className="option-button" htmlFor="health-yes">
                  Yes
                </label>
              </div>
              <div className="options-container">
                <input
                  type="radio"
                  id="health-no"
                  name={healthInsurance}
                  value="no"
                  checked={insuranceValue === "no"}
                  onChange={() => handleOptionChange("no")}
                ></input>
                <label className="option-button" htmlFor="health-no">
                  No
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="card-container">
        <div className="mainbg">
          <img src={card_in_hamd} alt=""></img>
        </div>
      </div>
    </>
  );
}
export default Income;
