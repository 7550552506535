// import { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import { RINGBA_STORAGE_KEYS, useGeneratorQuery } from "wecall-config-lib";
// import card_in_hamd from "../assets/bg4.png";
// import { ROUTES, sessionStorageRoutes } from "../component/Constant";

// function MedicareMedicaid({ headline }) {
//   const generatorQuery = useGeneratorQuery();
//   const navigate = useNavigate();

//   const setValues = (value) => {
//     sessionStorage.setItem(RINGBA_STORAGE_KEYS.medicareMedicaid, value);
//     window._rgba_tags = window._rgba_tags || [];
//     window._rgba_tags.push({ [RINGBA_STORAGE_KEYS.medicareMedicaid]: value });
//   };

//   const externalRedirectLink = window.domain_settings.medicareMedicAidYesLink;

//   useEffect(() => {
//     generatorQuery.set(window.location.search);
//     if (!sessionStorage.getItem(sessionStorageRoutes.income))
//       return navigate({
//         pathname: "../" + ROUTES.income,
//         search: generatorQuery.get(),
//       });
//     sessionStorage.setItem(sessionStorageRoutes.medicareMedicaid, true);
//   }, []);
//   return (
//     <>
//       <div className="question-wrapper">
//         <div className="headline-wrapper">
//           <h1>{headline}</h1>
//         </div>
//         <form method="POST" className="question-item">
//           <div className="question">
//             <h2>Are you on Medicare or Medicaid?</h2>

//             <div className="option-renderer-wrapper">
//               <div className="options-container">
//                 <input
//                   type="radio"
//                   id="medicare_medicaid_yes"
//                   name={RINGBA_STORAGE_KEYS.medicareMedicaid}
//                   value="yes"
//                   onChange={() => {
//                     setValues("yes");
//                     const newWindow = window.open(
//                       externalRedirectLink,
//                       "_blank"
//                     );
//                     newWindow.opener = null;
//                     newWindow.location.href = externalRedirectLink;
//                     newWindow.addEventListener("load", () => {
//                       newWindow.history.pushState(
//                         null,
//                         null,
//                         newWindow.location.href
//                       );
//                       newWindow.history.pushState(
//                         null,
//                         null,
//                         newWindow.location.href
//                       );
//                     });
//                   }}
//                 ></input>
//                 <label
//                   className="option-button"
//                   htmlFor="medicare_medicaid_yes"
//                 >
//                   Yes
//                 </label>
//               </div>

//               <div className="options-container">
//                 <input
//                   type="radio"
//                   id="medicare_medicaid_no"
//                   name={RINGBA_STORAGE_KEYS.medicareMedicaid}
//                   value="no"
//                   onChange={() => {
//                     setValues("no");
//                     navigate("../" + ROUTES.congrats, {
//                       search: generatorQuery.get(),
//                     });
//                   }}
//                 ></input>

//                 <label className="option-button" htmlFor="medicare_medicaid_no">
//                   No
//                 </label>
//               </div>
//             </div>
//           </div>
//         </form>
//       </div>
//       <div className="card-container">
//         <div className="mainbg">
//           <img src={card_in_hamd} alt=""></img>
//         </div>
//       </div>
//     </>
//   );
// }

// export default MedicareMedicaid;
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RINGBA_STORAGE_KEYS, useGeneratorQuery } from "wecall-config-lib";
import card_in_hamd from "../assets/bg4.png";
import { ROUTES, sessionStorageRoutes } from "../component/Constant";

function MedicareMedicaid({ headline }) {
  const generatorQuery = useGeneratorQuery();
  const navigate = useNavigate();
  const [medicareValue, setMedicareValue] = useState("");
  const externalRedirectLink = window.domain_settings?.medicareMedicAidYesLink;

  const handleMedicareOptionChange = (value) => {
    setMedicareValue(value);

    sessionStorage.setItem(RINGBA_STORAGE_KEYS.medicareMedicaid, value);
    window._rgba_tags = window._rgba_tags || [];
    window._rgba_tags.push({ [RINGBA_STORAGE_KEYS.medicareMedicaid]: value });

    if (value === "yes" && externalRedirectLink) {
      const newWindow = window.open(externalRedirectLink, "_blank");
      newWindow.opener = null;
      newWindow.location.href = externalRedirectLink;
      newWindow.addEventListener("load", () => {
        newWindow.history.pushState(null, null, newWindow.location.href);
        newWindow.history.pushState(null, null, newWindow.location.href);
      });
    } else {
      navigate("../" + ROUTES.congrats, {
        search: generatorQuery.get(),
      });
    }
  };

  useEffect(() => {
    generatorQuery.set(window.location.search);
    if (!sessionStorage.getItem(sessionStorageRoutes.income))
      return navigate({
        pathname: "../" + ROUTES.income,
        search: generatorQuery.get(),
      });
    sessionStorage.setItem(sessionStorageRoutes.medicareMedicaid, true);
  }, []);

  return (
    <>
      <div className="question-wrapper">
        <div className="headline-wrapper">
          <h1>{headline}</h1>
        </div>
        <form method="POST" className="question-item">
          <div className="question">
            <h2>Are you on Medicare or Medicaid?</h2>

            <div className="option-renderer-wrapper">
              <div className="options-container">
                <input
                  type="radio"
                  id="medicare_medicaid_yes"
                  name={RINGBA_STORAGE_KEYS.medicareMedicaid}
                  value="yes"
                  checked={medicareValue === "yes"}
                  onChange={() => {
                    handleMedicareOptionChange("yes");
                  }}
                ></input>
                <label
                  className="option-button"
                  htmlFor="medicare_medicaid_yes"
                >
                  Yes
                </label>
              </div>

              <div className="options-container">
                <input
                  type="radio"
                  id="medicare_medicaid_no"
                  name={RINGBA_STORAGE_KEYS.medicareMedicaid}
                  value="no"
                  checked={medicareValue === "no"}
                  onChange={() => handleMedicareOptionChange("no")}
                ></input>

                <label className="option-button" htmlFor="medicare_medicaid_no">
                  No
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="card-container">
        <div className="mainbg">
          <img src={card_in_hamd} alt=""></img>
        </div>
      </div>
    </>
  );
}

export default MedicareMedicaid;
